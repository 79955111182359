import request from '../utils/request';


/**
 * 获取验证码
 * @param phone
 * @returns {AxiosPromise}
 */
export function getList(){
    return request({
        url: '/sys/logUpdate/selectPageList',
        method: 'get',
    });
}


/**
 * 批量延期
 */
export const getLog = (query) => {
    console.log(query)
    return request({
        url: '/sys/logUpdate/selectPageList',
        method: 'POST',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};