<template>
  <div style="padding: 20px">
    <div class="handle-box">
      <div>
        <el-select v-model="query.methodDescription" filterable placeholder="修改类型" class="mr10">
          <el-option
              v-for="item in updateTypeList"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
        <el-input v-model="query.content" clearable placeholder="请输入修改人姓名，或备注内容" style="width: 260px" class="mr10"></el-input>
        <el-date-picker
            style="width: 260px;
            margin-right: 10px"
            v-model="chooseDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-button @click="getData()">搜索</el-button>
      </div>
    </div>
    <el-table
        height="640px"
        v-loading="tableLoading"
        element-loading-text="请稍等..."
        :data="logData"
        border
        class="table"
        ref="multipleTable"
        :row-style="{height: '10'}"
        :cell-style="{padding: '10px ,20px'}"
        header-cell-class-name="table-header"
    >
      <el-table-column
          label="修改人"
          prop="updateName"
          width="150">
      </el-table-column>
      <el-table-column
          width="180"
          prop="createTime"
          label="修改时间">
      </el-table-column>
      <el-table-column
          label="IP地址"
          prop="requestIp"
          width="180">
      </el-table-column>
      <el-table-column
          label="接口命名"
          show-overflow-tooltip="true"
          prop="methodDescription"
          width="160">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          label="接口地址"
          prop="method">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          width="200"
          label="修改内容"
          prop="detailList">
        <template v-slot="{row}">
          <el-popover
              append-to-body="true"
              placement="top"
              border
              :width="600"
              trigger="click"
          >
              <el-table :data=" row.detailList" size="mini">
                <el-table-column prop="tableId" label="更新表Id" width="90" />
                <el-table-column prop="tableName" show-overflow-tooltip="true" label="更新表名" width="120" />
                <el-table-column prop="fieldName" show-overflow-tooltip="true" label="更新字段" width="100" />
                <el-table-column prop="fieldDescribe" show-overflow-tooltip="true" label="字段描述" width="100" />
                <el-table-column prop="oldData" show-overflow-tooltip="true" label="旧内容" width="90" />
                <el-table-column prop="newData" show-overflow-tooltip="true" label="新内容" width="90" />
              </el-table>
            <template #reference>
              <el-button size="mini" type="primary">查看详情</el-button>
            </template>
          </el-popover>


        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          label="修改说明"
          prop="updateExplain">
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          border
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="logDataTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getLog,getList} from "@/api/log.js";
export default {
  name: "OverallLog",
  data() {
    return {
      logData:[],
      logDataTotal:0,
      tableLoading:false,
      updateTypeList:[],
      chooseDate:null,
      query:{
        methodDescription:null,
        content:"",
        pageIndex: 0,
        pageSize: 11
      }
    }
  },
  methods:{
    getData(){
      this.tableLoading = true;
      if (this.chooseDate!=null && this.chooseDate.length >= 2) {
        this.query.startTime = this.dateFormat(this.chooseDate[0]);
        this.query.endTime = this.dateFormat(this.chooseDate[1]);
      }else {
        this.query.startTime = null;
        this.query.endTime = null;
      }
      getLog(this.query).then(val=>{
        this.tableLoading = false;
        this.logData = val.data.records;
        this.logDataTotal = val.data.total
      })
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    //格式化 年-月-日
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
  },
  created() {
    this.getData();
    getList().then(val=>{
      this.updateTypeList = val.data
    })
  }
}
</script>

<style scoped>
.handle-box {
  display: flex;
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 10px;
}
</style>